import React, { forwardRef, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import styles from '../../styles/slices/Callout.module.scss';
import Link from 'next/link';
import classnames from 'classnames';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Image from 'next/image';
import { useRouter } from 'next/router';

const Callout = ({ slice }, ref) => {
  const router = useRouter();
  const pathSegments = router.pathname.split('/'); // Split the pathname
  const segment = pathSegments[1]; // Get the second segment
  const isPostPage = ['resources', 'article'].includes(segment);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  if (isPostPage) {
    slice.primary.description = [
      {
        type: 'paragraph',
        text: "Discover how Fermata Energy's intelligent V2X platform can deliver smart energy solutions for your business.",
        spans: [],
        direction: 'ltr',
      },
    ];

    slice.primary.title = [
      {
        type: 'heading1',
        text: 'Ready to Get Started?',
        spans: [],
        direction: 'ltr',
      },
    ];

    slice.linktext = "Request Info";
    slice.primary.backgroundcolor = 'violet';
  }
  return (
    <section
      className={classnames(
        styles.callout_section,
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={slice.primary.uid || ''}
      ref={ref}
    >
      <div
        className={classnames(
          styles.callout_content,
          slice.primary.backgroundcolor &&
            slice.primary.backgroundcolor === 'violet'
            ? styles.callout_violet
            : slice.primary.backgroundcolor &&
              slice.primary.backgroundcolor === 'red'
            ? styles.callout_red
            : slice.primary.backgroundcolor &&
              slice.primary.backgroundcolor === 'white'
            ? styles.callout_white
            : slice.primary.backgroundcolor &&
              slice.primary.backgroundcolor === 'light-grey'
            ? styles.callout_light_grey
            : slice.primary.backgroundcolor &&
              slice.primary.backgroundcolor === 'dark-grey'
            ? styles.callout_dark_grey
            : styles.callout_orange
        )}
        ref={wrapperRef}
      >
        <div className={styles.callout_title}>
          {slice.primary.title ? (
            <PrismicRichText field={slice.primary.title} />
          ) : null}
        </div>
        {slice.primary.description && slice.primary.linkcta ? (
          <div className={styles.callout_info_wrapper}>
            <div className={styles.callout_description}>
              <PrismicRichText field={slice.primary.description} />
            </div>

            <div className={styles.calloutbtn}>
              <Link
                href={slice.primary.linkcta.url}
                target={slice.primary.linkcta.target}
              >
                <button
                  name="callout link"
                  className={classnames(
                    styles.btn,
                    styles.secondary_white
                  )}
                >
                  <span>{slice.primary.linktext}</span>
                </button>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default forwardRef(Callout);
